export const classicData = [
  {
    name: "Массаж воротниковой зоны (20 минут)",
    price: "1000 ₽",
  },
  {
    name: "Массаж воротниковой зоны+верхних конечностей (40минут)",
    price: "1500 ₽",
  },
  {
    name: "Массаж шейно-грудного отдела (30минут)",
    price: "1500 ₽",
  },
  {
    name: "Массаж пояснично-крестцовой зоны + ягодицы (30 минут)",
    price: "1500 ₽",
  },
  {
    name: "Массаж пояснично-крестцовой зоны+ягодицы+нижних конечностей (50минут)",
    price: "2000 ₽",
  },
  {
    name: "Массаж всех отделов позвоночника (40минут)",
    price: "1800 ₽",
  },
  {
    name: "Общий массаж тела (1час 20минут)",
    price: "2800 ₽",
  },
  {
    name: "Массаж всех отделов позвоночника + ягодичная область (50минут)",
    price: "2000 ₽",
  },
  {
    name: "Релаксирующий английский массаж по аромамаслу (1час 30минут)",
    price: "3000 ₽",
  },
  {
    name: "Подводный общеоздоровительный массаж (25минут)",
    price: "850 ₽",
  },
  {
    name: "Кедровая фито-бочка",
    price: "850 ₽",
  },
];
