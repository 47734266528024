export const cosmeticData = [
  {
    name: "Классический уход по лицу (очищение, массаж, маска). Время процедуры 1час 10минут",
    price: "2500 ₽",
  },
  {
    name: "Косметический массаж. Время процедуры 40 мин",
    price: "1500 ₽",
  },
  {
    name: "Парафин для рук",
    price: "300 ₽",
  },
  {
    name: "У нас вы можете приобрести подарочный сертификат",
    price: "",
  },
];
