export const correctionData = [
  {
    name: "Антицеллюлитные, общеоздоровительные обертывания в термоодеяле с водорослями, грязями, различными видами глины и другими косметическими средствами",
    price: "от 1500 ₽",
  },
  {
    name: "VIP-уход (скраб, общий массаж по водорослям, грязям и другими косметическими средствами). Продолжительность процедуры 2 часа",
    price: "от 4000 ₽",
  },
  {
    name: "Скраб + обёртывание + VIP-уход (обертывание с детоксикационными средствами, массаж по водорослям, грязям и другими косметическими средствами). Продолжительность процедуры 3 часа",
    price: "от 5000 ₽",
  },
  {
    name: "Антицеллюлитный массаж (ноги, ягодицы) (50минут)",
    price: "2300 ₽",
  },
  {
    name: "Корректирующий массаж боковой части туловища (от подмышечной впадины до тазобедренного сустава) (30минут)",
    price: "1300 ₽",
  },
  {
    name: "Общий корректирующий массаж проблемных зон (1час 30 минут)",
    price: "3500 ₽",
  },
  {
    name: "Корректирующий массаж живота",
    price: "1000 ₽",
  },
  {
    name: "Моделирующий массаж груди",
    price: "1000 ₽",
  },
  {
    name: "Подводный антицеллюлитный массаж",
    price: "850 ₽",
  },
];
