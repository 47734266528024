export const childData = [
  {
    name: "До года",
    price: "1300 ₽",
  },
  {
    name: "От 1 года до 3",
    price: "1600 ₽",
  },
  {
    name: "От 3 до 6 лет",
    price: "1900 ₽",
  },
  {
    name: "От 6 до 10",
    price: "2200 ₽",
  },
  {
    name: "Возможен выезд на дом",
    price: "+500 ₽",
  },
  {
    name: "Цена указана за общий массаж",
    price: "",
  },
];
